import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../components/common/PageHeader"
export default function Seminar() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 578 }) {
        id
        content
        title
      }
    }
  `)
  const { content } = data.wpPage
  return (
    <Layout>
      <div className="max-w-4xl space-y-8">
        <PageHeader text={data.wpPage.title} />
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="wp-content seminar"
        />
      </div>
    </Layout>
  )
}
